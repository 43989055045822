import * as React from "react"
import { Router } from "@reach/router"
import { Helmet } from 'react-helmet';

import config from "../utils/config"
import Layout from "../components/Layout"
import defaults from "../components/dafaults"

import Loading from "../components/shared/Loading";

const SomeSubPage = props => {

  const [ data, setData ] = React.useState(null)

  React.useLayoutEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${defaults.api.rest}pages/?slug=${props.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(data == null)
              setData(result[0])
        })
        .catch(error => console.log('error', error));
  })


  return (
    data == null ?
      <Loading />
    :
      <>
        <Helmet>
          <title>{ data.yoast_head_json.title }</title>
          <meta name="description" content={ data.yoast_head_json.description } />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <link rel="canonical" href={ data.yoast_head_json.canonical.replace("admin.", "") } />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={ data.yoast_head_json.og_title } />
          <meta property="og:description" content={ data.yoast_head_json.og_description } />
          <meta property="og:url" content={ data.yoast_head_json.canonical.replace("admin.", "") } />
          <meta property="og:site_name" content="Wega" />
          <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
        </Helmet>
        <div className="content" id={props.id}>
          <h1>{ data.title.rendered }</h1>
          <div className="insideContent" dangerouslySetInnerHTML={{__html: data.content.rendered }} />
        </div>
      </>
  )
}

const Produtos = ({ serverData }) => (
  <Layout>
    <Router>
      <SomeSubPage path="/produtos/:id" />
    </Router>
  </Layout>
)

export default Produtos